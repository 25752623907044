/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "여덟 번째 에피소드의 번외편입니다. 본편 녹음을 끝내고 [@j2p][j2p] 님을 모시고 rebuild.fm, Plagger, 글쓰기 도구, 44bits.io에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/ikedaosushi/rebuild-dot-fmfalsetong-ji-xue"
  }, "(일본어) rebuild.fm의 통계학 - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/miyagawa/plagger"
  }, "miyagawa/plagger: Pluggable RSS/Atom aggregator")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://e8y.net/blog/2006/07/25/p126.html"
  }, "(일본어) Google에서「배고파」라고 검색하면 피자가 배달되도록 만들기까지")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://d.hatena.ne.jp/keyword/%A4%BD%A4%ECPlagger%A4%C7%A4%C7%A4%AD%A4%EB%A4%E8"
  }, "(일본어) 그거Plagger로할수있어 - 하테나 다이어리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://seapy.com/"
  }, "Seapy's Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://textbundle.org/"
  }, "Welcome to TextBundle.org!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jekyllrb.com/"
  }, "Jekyll • Simple, blog-aware, static sites - Transform your plain text into static websites and blogs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://imgur.com/"
  }, "Imgur: The magic of the Internet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.orgmode.org/index.html"
  }, "Org mode for Emacs – Your Life in Plain Text")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ulysses.app/blog/2017/10/ulysses-12-release/"
  }, "Out Now: Image Previews, Drag and Drop, iOS 11 - Ulysses Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Bear - Notes for iPhone, iPad and Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mattgemmell.com/using-the-ipad-for-writing-novels/"
  }, "Using the iPad for: Writing Novels - Matt Gemmell")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sciencetimes.co.kr/?news=%EB%8F%84%EC%8A%A4%EC%97%90%EC%84%9C-%EC%9E%91%EC%84%B1%EB%90%9C-%EC%99%95%EC%A2%8C%EC%9D%98-%EA%B2%8C%EC%9E%84"
  }, "도스에서 작성된 ‘왕좌의 게임’ – Sciencetimes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/denoland/deno"
  }, "denoland/deno: A secure TypeScript runtime on V8")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://firecracker-microvm.github.io/"
  }, "Firecracker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/awslabs/aws-lambda-rust-runtime"
  }, "awslabs/aws-lambda-rust-runtime: A Rust runtime for AWS Lambda")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/advent-calendar/2018/lambda-custom-runtimes"
  }, "(일본어) AWS Lambda Custom Runtimes芸人 Advent Calendar 2018 - Qiita")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://yeun.github.io/open-color/"
  }, "Open Color")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Line_wrap_and_word_wrap"
  }, "Line wrap and word wrap - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/terraform_introduction_infrastrucute_as_code"
  }, "테라폼(Terraform) 기초 튜토리얼: AWS로 시작하는 Infrastructure as Code - 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.google.co.kr/search?hl=ko&q=%ED%85%8C%EB%9D%BC%ED%8F%BC&lr=lang_ko"
  }, "테라폼 - Google 검색")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/"
  }, "Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://confconf.github.io/"
  }, "ConfConf 2016")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=I8JAl9B0Nrs&index=10&list=PLLpp1MOTRVCAhT6yzo2mm0DKYFO0c_ri6"
  }, "ConfConf 2016 - AWS re:invent 2015 (USA - Las Vegas) by 유용빈 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.businessinsider.com/google-gave-io-attendees-a-pixel-laptop-2013-5"
  }, "Google Gave I/O Attendees A Pixel Laptop - Business Insider")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dev.classmethod.jp/"
  }, "(일본어) Developers.IO")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wikidocs.net/book/1"
  }, "점프 투 파이썬 - WikiDocs")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bluedesigns.com/products/yeti/"
  }, "Blue - Yeti")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
